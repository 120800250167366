<template>
  <div>
    <modal @closed="closeModal" class="modal" name="createDeviceModal" :width="770" height="auto" :adaptive="true" :scrollable="true" style="z-index:1000">
      <button @click="closeModal" class="absolute right-0 m-3">
        <img src="@/assets/img/close.svg" alt="Close">
      </button>
      <div class="mt-10 mx-auto w-max">
        <h2>Device toevoegen</h2>
      </div>

      <formulate-form ref="locationForm" name="locationForm" v-model="formValues" @submit="createDevice()" class="mb-6">
        <div class="container">
          <formulate-input validation="required" error-behavior="submit" type="text" name="type" hidden v-model="deviceType"></formulate-input>
          <formulate-input validation="required" error-behavior="submit" type="text" name="reference" label="Device identifier"></formulate-input>
          <formulate-input type="submit" :label="$t('Bevestigen')" class="bg-wilmsBlue w-max text-white rounded-sm p-2"/>
        </div>
      </formulate-form>
    </modal>
  </div>
</template>

<script>
export default {
  props: ['open', 'room'],
  data () {
    return {
      formValues: {},
      deviceType: 'unit'
    }
  },
  methods: {
    closeModal () {
      this.$modal.hide('createDeviceModal')
      this.$emit('close')
    },
    openModal () {
      this.$modal.show('createDeviceModal')
    },
    async createDevice () {
      await this.$store.dispatch('DevicesModule/createDevice', {
        homeId: this.$route.params.home,
        roomId: this.room.id,
        device: this.formValues
      })
      this.$emit('close')
    }
  },
  watch: {
    open () {
      if (this.open) {
        this.openModal()
      } else {
        this.closeModal()
      }
    }
  }
}
</script>
