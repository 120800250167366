<template>
  <div class="container" v-if="location">
    <p class="text-wilmsBlue text-2xl font-bold mt-12">{{ $t('Details') }} {{ location.home.name }}</p>
    <p class="text-gray-500 font-medium -mt-1 mb-6">{{ $t('Discover everything about this location') }}</p>
    <div class="flex justify-end">
      <div @click="openRoomModal()" class="cursor-pointer bg-wilmsBlue py-2 px-5 text-white font-semibold rounded-sm">
        {{ $t('Add a new room') }}
      </div>
    </div>
    <div class="flex mt-5 lg:flex-col gap-5">
      <div class="flex-none">
        <div class="shadow-md rounded-sm">
          <MiniMap :home="location" />
          <div class="flex justify-between p-3">
            <div>
              <p class="text-wilmsBlue text-lg font-bold">{{ location.home.name }}</p>
              <p class="text-gray-500 font-medium -mt-1">{{ location.home.address }}</p>
              <p class="text-gray-500 font-medium -mt-1">{{ location.home.postcode + ' ' + location.home.city }}</p>
              <div class="flex gap-2">
                <div class="bg-wilmsBlueBackground w-max rounded-sm px-2 text-xs text-wilmsBlue font-medium mt-1 py-1">{{ totalDevices(location.home) }}</div>
                <div class="bg-red-200 w-max rounded-sm px-2 text-xs text-red-600 font-medium mt-1 py-1">{{ totalWarnings(location.home.warnings) }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="flex bg-green-200 w-6 h-6 rounded-full justify-center">
                <img src="@/assets/img/iot/np_customer.png" alt="" class="my-auto">
              </div>
              <p class="ml-2 text-gray-500 font-medium">--</p>
            </div>
          </div>
        </div>
        <div class="shadow-md rounded-sm flex justify-between p-3 mt-2">
          <p class="text-wilmsBlue font-bold text-sm">{{ $t('Installation') }}</p>
          <p class="text-gray-500 font-medium text-sm">{{ getDate(location.home.createdAt) }}</p>
        </div>
        <div class="shadow-md rounded-sm flex justify-between p-3 mt-2">
          <p class="text-wilmsBlue font-bold text-sm">{{ $t('Last maintenance') }}</p>
          <p class="text-gray-500 font-medium text-sm">{{ getDate(location.home.maintenance) }}</p>
        </div>
      </div>
      <div class="flex flex-col w-full gap-5 mb-10">
        <div class="shadow-md rounded-sm bg-white" v-for="room in rooms" :key="room.id">
          <div class="m-2 bg-wilmsBlueBackground rounded-sm flex">
            <img :src="getIcon(room.type)" class="w-10 h-10 text-wilmsBlue mx-2">
            <p class="text-wilmsBlue font-bold text-lg ml-1 mt-1 flex-auto whitespace-nowrap">{{ room.name }}</p>
            <div @click="openDeviceModal(room)" class="border-2 border-wilmsBlue w-max rounded-sm px-2 my-1 mr-3 cursor-pointer">
              <p class="font-semibold text-wilmsBlue whitespace-nowrap">{{ $t('Add a unit') }}</p>
            </div>
          </div>
          <div class="flex flex-col divide-y-default divide-gray-300 px-3">
            <div class="py-6 flex justify-between sm:flex-col sm:items-center sm:gap-5" v-for="device in room.devices" :key="device.id">
              <div class="flex-none flex">
                <div class="w-8 h-8 bg-yellow-100 rounded-full flex flex-wrap content-center justify-center mr-3 my-auto">
                  <img class="mx-auto h-6 text-yellow-400" alt="" src="@/assets/img/iot/ventilation_unit.svg"/>
                </div>
                <div>
                  <p class="text-gray-500 font-medium -mt-1">{{ $t('Status') }}: {{ device.connected ? $t('connected') : $t('not connected')}}</p>
                  <p class="text-gray-500 font-medium -mt-1">{{ $t('Mode') }}: {{ device.mode }}</p>
                </div>
              </div>
              <div class="flex-none">
                <p class="text-gray-500 font-medium -mt-1">{{ getHoursSince(device.hours) }} {{ $t('hours since') }} {{ getDate(location.home.maintenance) }}</p>
                <div class="bg-gray-200 w-68 h-3 rounded-full relative">
                  <div class="h-3 rounded-full" :style="'width: ' + getHoursPercentage(device.hours) + '%'" :class="[getHoursPercentage(device.hours) > 79 ? 'bg-red-500': 'bg-green-500']"></div>
                </div>
              </div>
              <div class="flex-none flex justify-end">
                <router-link :to="{ name: 'iot.installer.graphs', params: { home: location.home.id, device: device.id }}">
                  <div class="w-8 h-8 bg-wilmsBlueBackground rounded-full flex flex-wrap content-center justify-center cursor-pointer">
                    <Icon class="text-wilmsBlue text-xl" icon="fa:angle-right"/>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateRoomModal @close="openRoom = false" :open="openRoom"></CreateRoomModal>
    <CreateDeviceModal @close="openDevice = false" :open="openDevice" :room="currentRoom"></CreateDeviceModal>
  </div>
</template>

<script>
import moment from 'moment'
import MiniMap from '@/components/iot/maps/MiniMap'
import CreateRoomModal from '@/components/iot/modals/CreateRoom'
import CreateDeviceModal from '@/components/iot/modals/CreateDevice'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    CreateRoomModal,
    CreateDeviceModal,
    MiniMap,
    Icon
  },
  data () {
    return {
      location: null,
      rooms: null,
      openRoom: false,
      openDevice: false,
      currentRoom: null
    }
  },
  async mounted () {
    this.$emit('changeHeader', [[this.$t('IOT'), { name: 'iot.installer' }], [this.$t('Locations'), { name: 'iot.installer.homes' }],
      [this.$t('Details location'), { name: 'iot.installer.details', params: { home: this.$route.params.home } }]])
    await this.fetchData()
    this.$store.getters['HomesModule/getHomes'].forEach(location => {
      if (location.home.id === parseInt(this.$route.params.home)) this.location = location
    })
    this.rooms = this.$store.getters['RoomsModule/getRooms']
  },
  methods: {
    async fetchData () {
      if (this.$store.getters['HomesModule/getHomes'].length === 0) await this.$store.dispatch('HomesModule/fetchHomes')
      if (this.$store.getters['RoomsModule/getRoomTypes'].length === 0) await this.$store.dispatch('RoomsModule/fetchRoomTypes')
      await this.$store.dispatch('RoomsModule/fetchRooms', { homeId: this.$route.params.home })
    },
    totalDevices (home) {
      let total = 0
      home.rooms.forEach(room => { total += room.devices.length })
      return total === 1 ? total + ' unit' : total + ' units'
    },
    totalWarnings (warnings) {
      return warnings === 1 ? warnings + ' ' + this.$t('warning') : warnings + ' ' + this.$t('warnings')
    },
    openRoomModal () {
      this.openRoom = true
    },
    openDeviceModal (room) {
      this.currentRoom = room
      this.openDevice = true
    },
    getIcon (roomType) {
      return require(`@/assets/img/iot/room-icons/${roomType}.svg`)
    },
    getDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    getHoursSince (hours) {
      return parseInt(hours)
    },
    getHoursPercentage (hours) {
      return Math.min(Math.max(Math.ceil(hours / 250 * 100), 0), 100)
    }
  }
}
</script>
