<template>
  <div class="bg-white w-full overflow-hidden rounded-t-sm">
    <div style="height: 100px;" class="w-full">
      <l-map ref="map" :zoom="zoom" :center="getLatLng(home)" :options="mapOptions">
        <l-tile-layer :url="url"/>
      </l-map>
    </div>
  </div>
</template>

<script>
import { latLng } from 'leaflet'
import { LMap, LTileLayer } from 'vue2-leaflet'

export default {
  props: ['home'],
  components: {
    LMap,
    LTileLayer
  },
  data () {
    return {
      zoom: 16,
      center: latLng(50.93106, 5.33781),
      url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      mapOptions: {
        attributionControl: false,
        zoomControl: false
      }
    }
  },
  methods: {
    getLatLng (location) {
      return {
        lat: location.home.latitude,
        lng: location.home.longitude
      }
    },
    getMarkerImageUrl (location) {
      return require(`@/assets/img/iot/${location.home.marker ?? 'np_pin'}.svg`)
    }
  }
}
</script>
